/* eslint-disable @typescript-eslint/naming-convention */
type Color = 'red' | 'yellow' | 'orange' | 'green' | 'grey' | 'blue';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const statusColor: {[key in string]: Color} = {
	inactive: 'red',
	active: 'yellow',
	completed: 'green',
	review: 'grey',
};

export const QUALIFICATION_COLORS: {[key in string]: Color} = {
	MEMBER: 'blue',
	QUALIFIED: 'green',
	DISQUALIFIED: 'red',
	NA: 'grey',
};

export type InteractionOutcome = 'SUCCESS' | 'UNRESPONSIVE' | 'WANTS_FOLLOW_UP';

export type LatestInteractionType =
	| 'INTRO_MESSAGE'
	| 'COLD_CALL'
	| 'CALL_COMPLETE'
	| 'FOLLOW_UP_MESSAGE';

export const LATEST_INTERACTION_TYPE_NAMES: {
	[key in LatestInteractionType]: string;
} = {
	INTRO_MESSAGE: 'interaction_types.intro_message',
	COLD_CALL: 'interaction_types.cold_call',
	CALL_COMPLETE: 'interaction_types.call_complete',
	FOLLOW_UP_MESSAGE: 'interaction_types.follow_up_message',
};

export type QualificationStatus = 'QUALIFIED' | 'DISQUALIFIED';
export type QualificationStatusMap<T> = {[status in QualificationStatus]: T};
export type DocumentStatusType =
	| 'ERROR'
	| 'PENDING'
	| 'UPDATE_REQUIRED'
	| 'VERIFIED'
	| 'VALIDATED';
export const QUALIFICATION_STATUSES_NAMES: QualificationStatusMap<string> = {
	QUALIFIED: 'qual_status.qualified',
	DISQUALIFIED: 'qual_status.disqualified',
};

export const DISQUALIFICATION_REASONS_NAMES: {[key: string]: string} = {
	other_support: 'qualification.disqualification_reasons.other_support',
	age: 'qualification.disqualification_reasons.age',
	study_gap: 'qualification.disqualification_reasons.study_gap',
	requires_financing:
		'qualification.disqualification_reasons.requires_financing',
	tuition_budget: 'qualification.disqualification_reasons.tuition_budget',
	living_expenses_budget:
		'qualification.disqualification_reasons.living_expenses_budget',
	proof_of_funds: 'qualification.disqualification_reasons.proof_of_funds',
	grades: 'qualification.disqualification_reasons.grades',
	passport: 'qualification.disqualification_reasons.passport',
	document_refusal: 'qualification.disqualification_reasons.document_refusal',
	visa_refusal: 'qualification.disqualification_reasons.visa_refusal',
	app_fee_refusal: 'qualification.disqualification_reasons.app_fee_refusal',
	expired_visa: 'qualification.disqualification_reasons.expired_visa',
	english_proficiency_test:
		'qualification.disqualification_reasons.english_proficiency_test',
	sponsor_support: 'qualification.disqualification_reasons.sponsor_support',
	financing_amount: 'qualification.disqualification_reasons.financing_amount',
	financially_prepared:
		'qualification.disqualification_reasons.financially_prepared',
	sponsor_relationship:
		'qualification.disqualification_reasons.sponsor_relationship',
	td_funds_available:
		'qualification.disqualification_reasons.td_funds_available',
	immigration_pof: 'qualification.disqualification_reasons.immigration_pof',
	interest: 'qualification.disqualification_reasons.interest',
	citizenship: 'qualification.disqualification_reasons.citizenship',
	visa_status: 'qualification.disqualification_reasons.visa_status',
	location: 'qualification.disqualification_reasons.location',
	ineligible_for_financing:
		'qualification.disqualification_reasons.ineligible_for_financing',
	nod: 'qualification.disqualification_reasons.nod',
	no_program: 'qualification.disqualification_reasons.no_program',
};

export const QUALIFICATION_STATUSES = [
	...(Object.keys(
		QUALIFICATION_STATUSES_NAMES,
	) as (keyof typeof QUALIFICATION_STATUSES_NAMES)[]),
];

export type LevelOfStudy =
	| 'BACHELORS'
	| 'MASTERS'
	| 'UNDERGRAD_DIPLOMA'
	| 'POSTGRAD_DIPLOMA'
	| 'UNDERGRAD_CERTIFICATE'
	| 'POSTGRAD_CERTIFICATE'
	| 'ASSOCIATE_DEGREE';

export const STUDY_LEVELS: {[key in LevelOfStudy]: string} = {
	BACHELORS: 'study_levels.bachelors',
	MASTERS: 'study_levels.masters',
	UNDERGRAD_DIPLOMA: 'study_levels.undergrad_diploma',
	POSTGRAD_DIPLOMA: 'study_levels.postgrad_diploma',
	UNDERGRAD_CERTIFICATE: 'study_levels.undergrad_certificate',
	POSTGRAD_CERTIFICATE: 'study_levels.postgrad_certificate',
	ASSOCIATE_DEGREE: 'study_levels.associate_degree',
};

export type DeliveryMethod = 'ONLINE' | 'IN_PERSON' | 'HYBRID';

export const DELIVERY_METHODS: {[key in DeliveryMethod]: string} = {
	ONLINE: 'delivery_methods.online',
	IN_PERSON: 'delivery_methods.in_person',
	HYBRID: 'delivery_methods.hybrid',
};

export const BOOLEAN = {
	Yes: true,
	No: false,
};

export const BOOLEAN_STRING = {
	true: 'yes',
	false: 'no',
};

export type PassportStatus = 'YES' | 'NO' | 'APPLIED';

export const PASSPORT_STATUS: {[key in PassportStatus]: string} = {
	YES: 'passport_status.yes',
	NO: 'passport_status.no',
	APPLIED: 'passport_status.applied',
};

export type SponsorRelationship =
	| 'PARENT'
	| 'SIBLING'
	| 'UNCLE'
	| 'OTHER_FAMILY'
	| 'FRIEND';

export const SPONSOR_RELATIONSHIPS: {[key in SponsorRelationship]: string} = {
	PARENT: 'sponsor_relationships.parent',
	SIBLING: 'sponsor_relationships.sibling',
	UNCLE: 'sponsor_relationships.uncle',
	OTHER_FAMILY: 'sponsor_relationships.other_family',
	FRIEND: 'sponsor_relationships.friend',
};

export type SponsorSupport = 'TUITION' | 'TUITION_DEPOSIT' | 'PROOF_OF_FUNDS';

export const SPONSOR_SUPPORT: {[key in SponsorSupport]: string} = {
	TUITION: 'sponsor_support.tuition',
	TUITION_DEPOSIT: 'sponsor_support.tuition_deposit',
	PROOF_OF_FUNDS: 'sponsor_support.proof_of_funds',
};

export type FinancingAmount = 'ALL' | 'HALF' | 'MINIMAL';

export const FINANCING_AMOUNT: {[key in FinancingAmount]: string} = {
	ALL: 'financing_amount.all',
	HALF: 'financing_amount.half',
	MINIMAL: 'financing_amount.minimal',
};

export type StudentGroup =
	| 'ONSHORE'
	| 'OFFSHORE'
	| 'ENGLISH_EXEMPT'
	| 'DOMESTIC';

export const STUDENT_GROUP_TYPES: {[key in StudentGroup]: string} = {
	ONSHORE: 'students.groups.onshore',
	OFFSHORE: 'students.groups.offshore',
	ENGLISH_EXEMPT: 'students.groups.english_exempt',
	DOMESTIC: 'students.groups.domestic',
};

export type Ambassador =
	| 'SUB_AGENT'
	| 'BRAND_AMBASSADOR'
	| 'INFLUENCER'
	| 'SCHOOL_PARTNER'
	| 'BUSINESS_PARTNER'
	| 'AMBASSADOR'
	| 'OTHER';

export const AMBASSADOR_TYPES: {[key in Ambassador]: string} = {
	SUB_AGENT: 'ambassador.ambassador_types.sub_agent',
	BRAND_AMBASSADOR: 'ambassador.ambassador_types.brand_ambassador',
	INFLUENCER: 'ambassador.ambassador_types.influencer',
	SCHOOL_PARTNER: 'ambassador.ambassador_types.school_partner',
	BUSINESS_PARTNER: 'ambassador.ambassador_types.business_partner',
	AMBASSADOR: 'ambassador.ambassador_types.ambassador',
	OTHER: 'ambassador.ambassador_types.other',
};

export const CURRENT_MAJOR_TYPES: {[key: string]: string} = {
	Business: 'Business',
	Sciences: 'Sciences',
	Engineering: 'Engineering',
	'Math, Engineering, Computer Science': 'Math, Engineering & Computer Science',
	'Creative Arts': 'Creative Arts',
	'Social Studies': 'Social Studies',
	'Social Studies & Humanities': 'Social Studies & Humanities',
	Education: 'Education',
	'Skilled Trades': 'Skilled Trades',
	Other: 'Other',
};

export type CoachingPipeline =
	| 'VERIFICATION_MESSAGING'
	| 'VERIFICATION_CALLING'
	| 'VERIFICATION_MESSAGING_ONSHORE'
	| 'VERIFICATION_CALLING_ONSHORE'
	| 'VERIFICATION_AUTO_CALLING'
	| 'COACHING'
	| 'DOMESTIC';

export type CoachingPipelineMap<T> = {[pipeline in CoachingPipeline]: T};

export const COACHING_PIPELINE_NAMES: CoachingPipelineMap<string> = {
	VERIFICATION_MESSAGING: 'coach.pipelines.verification_messaging',
	VERIFICATION_CALLING: 'coach.pipelines.verification_calling',
	VERIFICATION_MESSAGING_ONSHORE:
		'coach.pipelines.verification_messaging_onshore',
	VERIFICATION_CALLING_ONSHORE: 'coach.pipelines.verification_calling_onshore',
	VERIFICATION_AUTO_CALLING: 'coach.pipelines.verification_auto_calling',
	COACHING: 'coach.pipelines.coaching',
	DOMESTIC: 'coach.pipelines.domestic',
};

export const COACHING_PIPELINES = [
	...(Object.keys(
		COACHING_PIPELINE_NAMES,
	) as (keyof typeof COACHING_PIPELINE_NAMES)[]),
];

export type ApplicationProfileStepType =
	| 'NOT_STARTED'
	| 'PERSONAL_INFORMATION'
	| 'ADDRESS_DETAILS'
	| 'EDUCATION_SUMMARY'
	| 'ENGLISH_TEST_SCORE'
	| 'BACKGROUND_INFORMATION'
	| 'EMERGENCY_CONTACT'
	| 'COMPLETED';

export const APPLICATION_PROFILE_STAGES: ApplicationProfileStepType[] = [
	'NOT_STARTED',
	'PERSONAL_INFORMATION',
	'ADDRESS_DETAILS',
	'EDUCATION_SUMMARY',
	'ENGLISH_TEST_SCORE',
	'BACKGROUND_INFORMATION',
	'EMERGENCY_CONTACT',
	'COMPLETED',
];

export type MaritalStatus = 'SINGLE' | 'MARRIED';

export const MARITAL_STATUS_NAMES: {[key in MaritalStatus]: string} = {
	SINGLE: 'application_profile.personal_information.marital_statuses.single',
	MARRIED: 'application_profile.personal_information.marital_statuses.married',
};

export type Gender = 'MALE' | 'FEMALE';

export const GENDER_NAMES: {[key in Gender]: string} = {
	MALE: 'application_profile.personal_information.genders.male',
	FEMALE: 'application_profile.personal_information.genders.female',
};

export interface Currency {
	amount: number;
	currency: string;
}

export const COST_BY_STUDY_LEVEL: {[key in LevelOfStudy]: string} = {
	MASTERS: '50,780',
	POSTGRAD_DIPLOMA: '47,730',
	POSTGRAD_CERTIFICATE: '45,230',
	UNDERGRAD_DIPLOMA: '44,230',
	UNDERGRAD_CERTIFICATE: '44,230',
	BACHELORS: '48,780',
	ASSOCIATE_DEGREE: '48,780',
};

export type StudentType =
	| 'OFFSHORE'
	| 'ONSHORE'
	| 'ONSHORE_EXTENDER'
	| 'ONSHORE_SWITCHER'
	| 'DOMESTIC';
