import {useEffect, useMemo, useState} from 'react';
import {constructServerSideProps} from 'utils/ssr';
import type {CoachingType} from 'modules/Coaching';
import {CoachingDropdown, CoachingMetricsDocument} from 'modules/Coaching';
import {withUserProtectedRoute} from '@halp/foundation/Users';
import {Page, Stack} from '@halp/ui';
import {useQuery} from '@halp/api/graphql';
import {AdminCoachesIndexDocument} from '@halp/foundation/Coaches';
import {StudentsByPipeline, CoachStats} from 'modules/Metrics';
import {useRouter} from 'next/router';
import {getCoachingTypeFromSlug} from '../../modules/Coaching/coaching-prefix';

function CoachingIndex() {
	const {
		query: {coachingType: coachingTypeSlug},
	} = useRouter();
	const [signUpAfter, setSignUpAfter] = useState<Date | undefined>(undefined);
	const [metricsAsAt, setMetricsAsAt] = useState<Date | undefined>(undefined);
	const [selectedCoaches, setSelectedCoaches] = useState<string[]>([]);
	const [selectedVerificationAgents, setSelectedVerificationAgents] = useState<
		string[]
	>([]);
	const [coachingType, setCoachingType] = useState<CoachingType>(
		getCoachingTypeFromSlug(coachingTypeSlug),
	);

	const {data: coachesData} = useQuery(AdminCoachesIndexDocument, {
		variables: {archived: false, active: null},
	});

	const coaches = useMemo(
		() =>
			coachesData?.coaches.filter((coach) => coach.pipeline === 'COACHING') ??
			[],
		[coachesData],
	);

	const verificationAgents = useMemo(
		() =>
			coachesData?.coaches.filter((coach) => coach.pipeline !== 'COACHING') ??
			[],
		[coachesData],
	);

	useEffect(() => {
		setSelectedCoaches(
			coaches.filter((coach) => coach.active).map((coach) => coach.id),
		);
	}, [coaches]);

	useEffect(() => {
		setSelectedVerificationAgents(
			verificationAgents
				.filter((agent) => agent.active)
				.map((coach) => coach.id),
		);
	}, [verificationAgents]);

	const {data} = useQuery(CoachingMetricsDocument, {
		variables: {
			coachIds: selectedCoaches,
			verificationAgentIds: selectedVerificationAgents,
			signUpAfter,
			metricsAsAt,
		},
	});

	return (
		<Page
			header={
				<CoachingDropdown
					coaches={coaches}
					coachingType={coachingType}
					setCoachingType={setCoachingType}
				/>
			}
		>
			<Stack direction="column" fullWidth alignItems="normal" spacing="md">
				{coachingType === 'international' ? (
					<StudentsByPipeline
						verificationAgentMetrics={data?.verificationAgents ?? []}
						selectedVerificationAgents={selectedVerificationAgents}
						setSelectedVerificationAgents={setSelectedVerificationAgents}
						verificationAgents={verificationAgents}
					/>
				) : null}
				<CoachStats
					coachingType={coachingType}
					coachMetrics={data?.coaches ?? []}
					coaches={coaches}
					signUpAfter={signUpAfter}
					setSignUpAfter={setSignUpAfter}
					metricsAsAt={metricsAsAt}
					setMetricsAsAt={setMetricsAsAt}
					selectedCoaches={selectedCoaches}
					setSelectedCoaches={setSelectedCoaches}
				/>
			</Stack>
		</Page>
	);
}

export default withUserProtectedRoute(CoachingIndex, {role: 'admin'});
export const getServerSideProps = constructServerSideProps({
	dictionaries: [
		'to_do',
		'program_recommendation',
		'admin',
		'coach',
		'metrics',
		'application_profile',
	],
});
