export type FunnelStage =
	| 'SIGNUP'
	| 'VERIFICATION'
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'SUBMIT'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED';

export type FunnelStageMap<T> = {[stage in FunnelStage]: T};

export type CoachingFunnelStage = Exclude<
	FunnelStage,
	'SIGNUP' | 'VERIFICATION'
>;

export type DomesticCoachingFunnelStage = Exclude<
	DomesticFunnelStage,
	'SIGNUP'
>;

export type CoachingFunnelStageMap<T> = {[stage in CoachingFunnelStage]: T};
export type DomesticCoachingFunnelStageMap<T> = {
	[stage in DomesticCoachingFunnelStage]: T;
};

export const COACHING_FUNNEL_STAGE_NAMES: CoachingFunnelStageMap<string> = {
	GET_STARTED: 'students.funnel_stages.get_started',
	DISCOVER: 'students.funnel_stages.discover',
	PREP: 'students.funnel_stages.prep',
	SUBMIT: 'students.funnel_stages.submit',
	ACCEPTED: 'students.funnel_stages.accepted',
	VISA: 'students.funnel_stages.visa',
	SET_UP: 'students.funnel_stages.set_up',
	PLACED: 'students.funnel_stages.placed',
};

export const DOMESTIC_COACHING_FUNNEL_STAGE_NAMES: DomesticCoachingFunnelStageMap<string> =
	{
		PRE_CONSULT: 'students.domestic_funnel_stages.pre_consult',
		CONSULT: 'students.domestic_funnel_stages.consult',
		DECISION: 'students.domestic_funnel_stages.decision',
		HAND_OFF: 'students.domestic_funnel_stages.hand_off',
	};

export const FUNNEL_STAGE_NAMES: FunnelStageMap<string> = {
	SIGNUP: 'students.funnel_stages.signup',
	VERIFICATION: 'students.funnel_stages.verification',
	...COACHING_FUNNEL_STAGE_NAMES,
};

export const FUNNEL_STAGES = [
	...(Object.keys(FUNNEL_STAGE_NAMES) as (keyof typeof FUNNEL_STAGE_NAMES)[]),
];

export const COACHING_FUNNEL_STAGES = [
	...(Object.keys(
		COACHING_FUNNEL_STAGE_NAMES,
	) as (keyof typeof COACHING_FUNNEL_STAGE_NAMES)[]),
];

export const DOMESTIC_COACHING_FUNNEL_STAGES = [
	...(Object.keys(
		DOMESTIC_COACHING_FUNNEL_STAGE_NAMES,
	) as (keyof typeof DOMESTIC_COACHING_FUNNEL_STAGE_NAMES)[]),
];

export const STUDENT_STATUSES_SLUG: {
	[key: string]: CoachingFunnelStage | 'ARCHIVED';
} = {
	start: 'GET_STARTED',
	discover: 'DISCOVER',
	prep: 'PREP',
	apply: 'SUBMIT',
	accepted: 'ACCEPTED',
	visa: 'VISA',
	arrive: 'SET_UP',
	placed: 'PLACED',
	archived: 'ARCHIVED',
};

export type ProgressCardStages = Extract<
	FunnelStage,
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED'
>;

export const PROGRESS_STEP_NAMES: {[key in ProgressCardStages]: string} = {
	GET_STARTED: 'Get Started',
	DISCOVER: 'Discover Programs',
	PREP: 'Prep Applications',
	ACCEPTED: 'Track Offers',
	VISA: 'Apply for Visa',
	SET_UP: 'Set Up',
	PLACED: 'Land on Campus',
};

export const PROGRESS_STEPS = [
	...(Object.keys(PROGRESS_STEP_NAMES) as (keyof typeof PROGRESS_STEP_NAMES)[]),
];
export type ProgressStep = (typeof PROGRESS_STEPS)[number];

export type DomesticFunnelStage =
	| 'SIGNUP'
	| 'PRE_CONSULT'
	| 'CONSULT'
	| 'DECISION'
	| 'HAND_OFF';

export type DomesticFunnelStageMap<T> = {[stage in DomesticFunnelStage]: T};

export type CoachingDomesticFunnelStages = Exclude<
	DomesticFunnelStage,
	'SIGNUP'
>;

export type CoachingDomesticFunnelStageMap<T> = {
	[stage in CoachingDomesticFunnelStages]: T;
};

export const COACHING_DOMESTIC_FUNNEL_STAGE_NAMES: CoachingDomesticFunnelStageMap<string> =
	{
		PRE_CONSULT: 'students.domestic_funnel_stages.pre_consult',
		CONSULT: 'students.domestic_funnel_stages.consult',
		DECISION: 'students.domestic_funnel_stages.decision',
		HAND_OFF: 'students.domestic_funnel_stages.hand_off',
	};

export const DOMESTIC_FUNNEL_STAGE_NAMES: DomesticFunnelStageMap<string> = {
	SIGNUP: 'students.domestic_funnel_stages.signup',
	...COACHING_DOMESTIC_FUNNEL_STAGE_NAMES,
};

export const DOMESTIC_FUNNEL_STAGES = [
	...(Object.keys(
		DOMESTIC_FUNNEL_STAGE_NAMES,
	) as (keyof typeof DOMESTIC_FUNNEL_STAGE_NAMES)[]),
];

export const COACHING_DOMESTIC_FUNNEL_STAGES = [
	...(Object.keys(
		COACHING_DOMESTIC_FUNNEL_STAGE_NAMES,
	) as (keyof typeof COACHING_DOMESTIC_FUNNEL_STAGE_NAMES)[]),
];

export const DOMESTIC_STUDENT_STATUSES_SLUG: {
	[key: string]: CoachingDomesticFunnelStages | 'ARCHIVED';
} = {
	preConsult: 'PRE_CONSULT',
	consult: 'CONSULT',
	decision: 'DECISION',
	handoff: 'HAND_OFF',
	archived: 'ARCHIVED',
};
